import ServerProxy from "../../utils/serverproxy.js";

class {
    onCreate() {
        this.state= {
            taskName: null,
            project: null,
            startTime: null,
            tracking: false
        }
    }

    onMount() {
        ServerProxy.getActiveTask((task) => {
            this.state.taskName = task.name;
            this.state.project = task.project;
            this.state.startTime = task.start;
            this.state.tracking = true;
        });
    }
}

style {
    .active-task-bar {
        position: fixed;
        width:96%;
        z-index: 600;
        bottom: 2%;
        max-width: var(--content-width);
        background-image: linear-gradient(45deg, var(--oc-gray-7), var(--oc-gray-8));
    }

    .active-task-bar-timer {
        font-size: 20px;
        font-variant-numeric: tabular-nums;
    }
}

if(state.tracking)
    ui-linear-layout.active-task-bar paddedwide tertiary rounded raised aligncenter light
        @element fillspace
            ui-linear-layout vertical
                @element
                    ui-text -- ${state.taskName}
                @element
                    project project=state.project
        @element.active-task-bar-timer
            ui-timer start=state.startTime
        @element
            ui-button rounded icon='x'