.page-wrapper {
    position: relative;
}

.content-wrapper {
    position: relative;
}

.content-top-margin {
    margin-top: 60px !important;
}

.limit-width {
    max-width: var(--content-width);
}

.blended {
    background-color: inherit;
    border: none;
    color: inherit;
    font-family: var(--text-font);
    border-bottom: 1px solid rgba(0,0,0,0.25);
    border-radius: 0px;
}

@keyframes load {
    0% {transform: translateX(50px); opacity: 0;}
    100% {transform: translateX(0px); opacity: 1;}
}