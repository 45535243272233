class {
    onCreate() {
        this.state = {
            visible: false
        };
    }

    toggleVisibility() {
        if(!this.state.visible) {
            document.body.addEventListener('click', this, true);
            this.state.visible = true;
        }
        else {
            document.body.removeEventListener('click', this, true);
            this.state.visible = false;
        }
    }

    handleEvent(e) {
        switch (e.type) {
            case 'click': { // If the click is outside the popout-form close the form.
                let el = e.target;
                while(el) {
                    if(el.classList.contains('popout-form')) {
                        return;
                    }
                    el = el.parentElement || null;
                }
                this.toggleVisibility();
            }
        }
    }
}

style {
    .popout-form-wrapper {
        animation-name: fadeup;
        animation-duration: 0.5s;
        width: 100%;
        max-width: 800px;
        font-size: initial !important;
    }

    .popout-topbar > .ui-linear-layout {
        border-top-left-radius: var(--rounded-radius);
        border-top-right-radius: var(--rounded-radius);
    }

    .mobile-page-wrapper .popout-form-wrapper {
        z-index: 200 !important;
        height: 100%;
        touch-action: none;
    }

    .mobile-page-wrapper .popout-form {
        height: 100%;
    }
}

if(state.visible)
    ui-linear-layout.popout-form-fullscreen-wrapper vertical fullscreen placecenter aligncenter
        @element.popout-form-wrapper
            ui-element.popout-form rounded raised filled
                ui-linear-layout fillspace fullwidth vertical gutter="0px"
                    @element.popout-topbar
                        ui-linear-layout fillspace fullwidth secondary paddedwide aligncenter
                            @element
                                ui-button rounded icon="arrow-left" on-click('toggleVisibility')
                            @element fillspace
                                ui-heading -- ${input.title}
                            if(input.controls && input.controls.renderBody)
                                @element
                                    <${input.controls.renderBody}/>
                    @element.popout-content-wrapper padded fillspace
                        <${input.renderBody}/>